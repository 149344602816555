import SecurityAPI from '../api/security'

const AUTHENTICATING = 'AUTHENTICATING'
const AUTHENTICATING_SUCCESS = 'AUTHENTICATING_SUCCESS'
const AUTHENTICATING_ACTIVE = 'AUTHENTICATING_ACTIVE'
const AUTHENTICATING_LOGOUT = 'AUTHENTICATING_LOGOUT'
const AUTHENTICATING_ERROR = 'AUTHENTICATING_ERROR'
const PROVIDING_DATA_ON_REFRESH_SUCCESS = 'PROVIDING_DATA_ON_REFRESH_SUCCESS'
const REQUEST_RESET = 'REQUEST_RESET'
const REQUEST_RESET_SUCCESS = 'REQUEST_RESET_SUCCESS'
const REQUEST_RESET_ERROR = 'REQUEST_RESET_ERROR'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: null,
        isAuthenticated: false,
        user: null,
        userIri: null,
        status: null,
        response: null
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        hasError(state) {
            return state.error !== null
        },
        error(state) {
            return state.error
        },
        isAuthenticated(state) {
            return state.isAuthenticated
        },
        userIri(state) {
            return state.userIri
        },
        user(state) {
            return state.user
        },
        status(state) {
            return state.status
        },
        response(state) {
            return state.response
        },
        hasRole(state) {
            return role => {
                return state.user.roles.indexOf(role) !== -1
            }
        }
    },
    mutations: {
        [AUTHENTICATING](state) {
            state.isLoading = true
            state.error = null
            state.isAuthenticated = false
            state.user = null
            // state.userIri = null
        },
        [AUTHENTICATING_SUCCESS](state, user) {
            state.isLoading = false
            state.error = null
            state.isAuthenticated = true
            state.user = user
            state.userIri = user.iri
        },
        [AUTHENTICATING_ACTIVE](state, user) {
            state.isLoading = false
            state.error = null
            state.isAuthenticated = true
            state.user = user
        },
        [AUTHENTICATING_LOGOUT](state, user) {
            state.isLoading = false
            state.error = null
            state.isAuthenticated = false
            state.user = null
            state.userIri = null
        },
        [AUTHENTICATING_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.isAuthenticated = false
            state.user = null
            state.userIri = null
        },
        [REQUEST_RESET](state) {
            state.isLoading = true
            state.error = null
            state.isAuthenticated = false
            state.user = null
        },
        [REQUEST_RESET_SUCCESS](state, data, status) {
            state.isLoading = false
            state.error = null
            state.isAuthenticated = false
            state.user = null
            state.response = data
            state.status = status
        },
        [REQUEST_RESET_ERROR](state, error, status) {
            state.isLoading = false
            state.error = error
            state.isAuthenticated = false
            state.user = null
            state.userIri = null
            state.status = status
        },
        [PROVIDING_DATA_ON_REFRESH_SUCCESS](state, payload) {
            state.isLoading = false
            state.error = null
            state.isAuthenticated = payload.isAuthenticated
            state.user = payload.user
        }
    },
    actions: {
        async login({ commit }, payload) {
            commit(AUTHENTICATING)
            try {
                let response = await SecurityAPI.login(
                    payload.login,
                    payload.password
                )
                commit(AUTHENTICATING_SUCCESS, response.data)
                // response.headers.location
                return response.data
            } catch (error) {
                commit(AUTHENTICATING_ERROR, error)
                return null
            }
        },
        async checkAuth({ commit }) {
            commit(AUTHENTICATING)
            try {
                let response = await SecurityAPI.checkAuth()
                // commit(AUTHENTICATING_ACTIVE, response.data)
                commit(AUTHENTICATING_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(AUTHENTICATING_ERROR, error)
                return null
            }
        },
        async requestReset({ commit }, payload) {
            commit(REQUEST_RESET)
            try {
                let response = await SecurityAPI.requestReset(payload.email)
                commit(REQUEST_RESET_SUCCESS, response.data, response.status)
                return response.data
            } catch (error) {
                commit(REQUEST_RESET_ERROR, error, error.response.status)
                return null
            }
        },
        async changePassword({ commit }, payload) {
            commit(REQUEST_RESET)
            try {
                let response = await SecurityAPI.changePassword(payload)
                commit(REQUEST_RESET_SUCCESS, response.data, response.status)
                return response.data
            } catch (error) {
                commit(REQUEST_RESET_ERROR, error, error.response.status)
                return null
            }
        },
        async logout({ commit }) {
            commit(AUTHENTICATING)
            try {
                let response = await SecurityAPI.logout()
                commit(AUTHENTICATING_LOGOUT, response.data)
                return response.data
            } catch (error) {
                commit(AUTHENTICATING_ERROR, error)
                return null
            }
        },
        onRefresh({ commit }, payload) {
            commit(PROVIDING_DATA_ON_REFRESH_SUCCESS, payload)
        }
    }
}
