import CalendarAPI from '../api/calendar'

const CREATING_TIMESLOT = 'CREATING_TIMESLOT'
const CREATING_TIMESLOT_SUCCESS = 'CREATING_TIMESLOT_SUCCESS'
const CREATING_TIMESLOT_ERROR = 'CREATING_TIMESLOT_ERROR'
const FETCHING_TIMESLOTS = 'FETCHING_TIMESLOTS'
const FETCHING_TIMESLOTS_SUCCESS = 'FETCHING_TIMESLOTS_SUCCESS'
const FETCHING_TIMESLOTS_ERROR = 'FETCHING_TIMESLOTS_ERROR'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: null,
        timeslots: []
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        hasError(state) {
            return state.error !== null
        },
        error(state) {
            return state.error
        },
        timeslots(state) {
            return state.timeslots
        }
    },
    mutations: {
        [CREATING_TIMESLOT](state) {
            state.isLoading = true
            state.error = null
        },
        [CREATING_TIMESLOT_SUCCESS](state, timeslot) {
            state.isLoading = false
            state.error = null
            state.timeslots.unshift(timeslot)
        },
        [CREATING_TIMESLOT_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.timeslots = []
        },
        [FETCHING_TIMESLOTS](state) {
            state.isLoading = true
            state.error = null
        },
        [FETCHING_TIMESLOTS_SUCCESS](state, timeslots) {
            state.isLoading = false
            state.error = null
            state.timeslots = timeslots
        },
        [FETCHING_TIMESLOTS_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.timeslots = []
        }
    },
    actions: {
        async create({ commit }, timeslot) {
            commit(CREATING_TIMESLOT)
            try {
                let response = await CalendarAPI.create(timeslot)
                commit(CREATING_TIMESLOT_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(CREATING_TIMESLOT_ERROR, error)
                return null
            }
        },
        async list({ commit }, period) {
            commit(FETCHING_TIMESLOTS)
            try {
                let response = await CalendarAPI.list(period)
                commit(
                    FETCHING_TIMESLOTS_SUCCESS,
                    response.data['hydra:member']
                )
                return response.data
            } catch (error) {
                commit(FETCHING_TIMESLOTS_ERROR, error)
                return null
            }
        }
    }
}
