import axios from './axios'
import qs from 'qs'

export default {
    create(event) {
        return axios.post('/calendar_events?pagination=false', event)
    },
    list(period) {
        return axios.get('/calendar_events/?pagination=false', {
            params: {
                beginAt: {
                    after: period.start
                },
                endAt: {
                    before: period.end
                }
            },
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            }
        })
    },
    bookings(period) {
        return axios.get('/bookings/?pagination=false', {
            params: {
                confirmedDate: {
                    after: period.start,
                    before: period.end
                },
                properties: [
                    'locationRoute',
                    'locationStreetNumber',
                    'confirmedDate',
                    'product',
                    'status',
                    'uuid',
                    '@id',
                    status['event']
                ]
            },
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            }
        })
    }
}
