import axios from './axios'

export default {
    create(usergroup) {
        return axios.post('/usergroups', usergroup)
    },
    view(usergroup) {
        return axios.get('/usergroups/' + usergroup)
    },
    update(usergroup) {
        usergroup.usergroupInvoiceInformation = [
            usergroup.usergroupInvoiceInformation
        ]
        return axios.patch(usergroup['@id'], usergroup, {
            headers: {
                'Content-Type': 'application/merge-patch+json'
            }
        })
    },
    delete(usergroup) {
        return axios.delete(usergroup)
    },
    listAll() {
        return axios.get('/usergroups?pagination=false')
    },
    allCustomers() {
        return axios.get('/usergroups?type.type=is_customer&pagination=false')
    },
    allSellers() {
        return axios.get('/usergroups?type.type=is_seller&pagination=false')
    }
}
