import UsergroupAccessAPI from '../api/usergroupAccess'

const CREATING_ACCESS = 'CREATING_ACCESS'
const CREATING_ACCESS_SUCCESS = 'CREATING_ACCESS_SUCCESS'
const CREATING_ACCESS_ERROR = 'CREATING_ACCESS_ERROR'
const DELETING_ACCESS = 'DELETING_ACCESS'
const DELETING_ACCESS_SUCCESS = 'DELETING_ACCESS_SUCCESS'
const DELETING_ACCESS_ERROR = 'DELETING_ACCESS_ERROR'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: null,
        access: null,
        status: null
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        hasError(state) {
            return state.error !== null
        },
        error(state) {
            return state.error
        },
        usergroupAccess(state) {
            return state.access
        },
        status(state) {
            return state.status
        }
    },
    mutations: {
        [CREATING_ACCESS](state) {
            state.isLoading = true
            state.error = null
        },
        [CREATING_ACCESS_SUCCESS](state, access) {
            state.isLoading = false
            state.error = null
            state.access = access
        },
        [CREATING_ACCESS_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.access = null
        },
        [DELETING_ACCESS](state) {
            state.isLoading = true
            state.error = null
        },
        [DELETING_ACCESS_SUCCESS](state, response) {
            state.isLoading = false
            state.error = null
            state.access = null
            state.status = response.status
        },
        [DELETING_ACCESS_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.access = null
        }
    },
    actions: {
        async create({ commit }, access) {
            commit(CREATING_ACCESS)
            try {
                let response = await UsergroupAccessAPI.create(access)
                commit(CREATING_ACCESS_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(CREATING_ACCESS_ERROR, error)
                return null
            }
        },
        async delete({ commit }, access) {
            commit(DELETING_ACCESS)
            try {
                let response = await UsergroupAccessAPI.delete(access)
                commit(DELETING_ACCESS_SUCCESS, response)
                return response.data
            } catch (error) {
                commit(DELETING_ACCESS_ERROR, error)
                return null
            }
        }
    }
}
