import axios from './axios'

export default {
    getUser(user) {
        return axios.get('/users/' + user)
    },
    updateUser(user) {
        /*
        let data = [{
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone
        }]

        if (user.password) {
            data.push({password: user.password})
        }
        */

        return axios.patch(user.iri, user, {
            headers: {
                'Content-Type': 'application/merge-patch+json'
            }
        })
    },
    deleteUser(user) {
        return axios.delete('/users/' + user)
    }
}
