import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/Login')
    },
    {
        path: '/',
        component: () =>
            import(/* webpackChunkName: "overview" */ '../views/Overview'),
        meta: {
            requiresAuth: true,
            title: 'Översikt'
        }
    },
    {
        name: 'reset-password',
        path: '/reset-password',
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/Login'),
        meta: {
            title: 'Återställ lösenord'
        }
    },
    {
        name: 'change-password',
        path: '/reset_password/reset/:token',
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/Login'),
        meta: {
            title: 'Återställ lösenord'
        }
    },
    {
        name: 'NewBookingOld',
        path: '/old/booking',
        component: () =>
            import(/* webpackChunkName: "newBooking" */ '../views/booking/New'),
        meta: {
            requiresAuth: true,
            title: 'Ny bokning'
        }
    },
    {
        name: 'NewBooking',
        path: '/booking',
        props: { mobile: true },
        component: () =>
            import(/* webpackChunkName: "newBooking" */ '../views/booking/New'),
        meta: {
            requiresAuth: true,
            title: 'Ny bokning'
        }
    },
    {
        name: 'EditBooking',
        path: '/booking/:uuid/edit',
        component: () =>
            import(
                /* webpackChunkName: "editBooking" */ '../views/booking/Edit'
            ),
        meta: {
            requiresAuth: true,
            title: 'Ändra bokning'
        }
    },
    {
        name: 'ViewBooking',
        path: '/booking/:uuid',
        component: () =>
            import(
                /* webpackChunkName: "editBooking" */ '../views/booking/View'
            ),
        meta: {
            requiresAuth: true,
            title: 'Bokning'
        }
    },
    {
        path: '/booking/:uuid/upload',
        name: 'UploadMedia',
        component: () =>
            import(
                /* webpackChunkName: "uploadMedia" */ '../views/booking/Upload'
            ),
        meta: { requiresAuth: true }
    },
    {
        name: 'Calendar',
        path: '/calendar',
        component: () =>
            import(/* webpackChunkName: "calendar" */ '../views/Calendar'),
        meta: {
            requiresAuth: true,
            requiresRole: 'ROLE_SELLER',
            title: 'Kalender'
        }
    },
    {
        name: 'ListCustomer',
        path: '/customer',
        component: () =>
            import(
                /* webpackChunkName: "listCustomer" */ '../views/customer/List'
            ),
        meta: {
            requiresAuth: true,
            title: 'Kunder'
        }
    },
    {
        name: 'EditUsergroup',
        path: '/usergroup/:uuid/edit',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "listCustomer" */ '../views/customer/EditUsergroup'
            ),
        meta: {
            requiresAuth: true,
            title: 'Kunder'
        }
    },
    {
        name: 'ListProduct',
        path: '/product',
        component: () =>
            import(
                /* webpackChunkName: "listProduct" */ '../views/product/List'
            ),
        meta: {
            requiresAuth: true,
            requiresRole: 'ROLE_SELLER',
            title: 'Produkter'
        }
    },
    {
        name: 'Profile',
        path: '/profile',
        component: () =>
            import(/* webpackChunkName: "profile" */ '../views/Profile'),
        meta: {
            requiresAuth: true,
            title: 'Din profil'
        }
    },
    {
        name: 'Overview',
        path: '/overview',
        component: () =>
            import(/* webpackChunkName: "overview" */ '../views/Overview'),
        meta: {
            requiresAuth: true,
            title: 'Översikt'
        }
    },
    {
        name: 'History',
        path: '/history',
        component: () =>
            import(/* webpackChunkName: "history" */ '../views/History'),
        meta: {
            requiresAuth: true,
            title: 'Historik'
        }
    },
    {
        name: 'Gallery',
        path: '/gallery',
        component: () =>
            import(
                /* webpackChunkName: "listGallery" */ '../views/gallery/List'
            ),
        meta: {
            requiresAuth: true,
            title: 'Senaste gallerier'
        }
    },
    {
        name: 'Changelog',
        path: '/settings/changelog',
        component: () =>
            import(/* webpackChunkName: "changelog" */ '../views/Changelog'),
        meta: {
            requiresAuth: true,
            title: 'Versionshistorik'
        }
    },
    {
        name: 'Settings',
        path: '/settings',
        component: () =>
            import(/* webpackChunkName: "settings" */ '../views/Settings'),
        meta: {
            requiresAuth: true,
            title: 'Inställningar'
        }
    },
    {
        name: 'logout',
        path: '/logout',
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/Login')
    },
    { path: '*', redirect: '/' }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['security/isAuthenticated']) {
            if (
                to.matched.some(record => record.meta.requiresRole) &&
                store.getters['security/hasRole'](
                    record => record.meta.requiresRole
                )
            ) {
                next({ name: 'Overview' })
            }

            document.title = to.meta.title || 'Grey Light Ordersystem'
            next()
        } else {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            })
        }
    } else {
        next()
    }
})

export default router
