import { format, parseJSON, parseISO, parse } from 'date-fns'
import { sv } from 'date-fns/locale'

const locales = { sv }

export default function(
    date,
    formatStr = 'P',
    parseMethod = 'parseJSON',
    inputFormat = ''
) {
    let parsedDate
    if (parseMethod === 'parseJSON') {
        parsedDate = parseJSON(date)
    } else if (parseMethod === 'parseISO') {
        parsedDate = parseISO(date)
    } else {
        parsedDate = parse(date, inputFormat, new Date())
    }
    return format(parsedDate, formatStr, {
        locale: locales[window.__localeId__]
    })
}
