import UserAPI from '../api/user'

const FETCHING_USER = 'FETCHING_USER'
const FETCHING_USER_SUCCESS = 'FETCHING_USER_SUCCESS'
const FETCHING_USER_ERROR = 'FETCHING_USER_ERROR'
const UPDATING_USER = 'UPDATING_USER'
const UPDATING_USER_SUCCESS = 'UPDATING_USER_SUCCESS'
const UPDATING_USER_ERROR = 'UPDATING_USER_ERROR'
const DELETING_USER = 'DELETING_USER'
const DELETING_USER_SUCCESS = 'DELETING_USER_SUCCESS'
const DELETING_USER_ERROR = 'DELETING_USER_ERROR'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: null,
        user: null,
        status: null
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        hasError(state) {
            return state.error !== null
        },
        error(state) {
            return state.error
        },
        user(state) {
            return state.user
        }
    },
    mutations: {
        [FETCHING_USER](state) {
            state.isLoading = true
            state.error = null
            state.user = null
        },
        [FETCHING_USER_SUCCESS](state, user) {
            state.isLoading = false
            state.error = null
            state.user = user
        },
        [FETCHING_USER_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.user = null
        },
        [UPDATING_USER](state) {
            state.isLoading = true
            state.error = null
            state.user = null
        },
        [UPDATING_USER_SUCCESS](state, user) {
            state.isLoading = false
            state.error = null
            state.user = user
        },
        [UPDATING_USER_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.user = null
        },
        [DELETING_USER](state) {
            state.isLoading = true
            state.error = null
            state.user = null
        },
        [DELETING_USER_SUCCESS](state, status) {
            state.isLoading = false
            state.error = null
            state.user = null
            state.status = status
        },
        [DELETING_USER_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.user = null
        }
    },
    actions: {
        async getUser({ commit }, user) {
            commit(FETCHING_USER)
            try {
                let response = await UserAPI.getUser(user)
                commit(FETCHING_USER_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(FETCHING_USER_ERROR, error)
                return null
            }
        },
        async updateUser({ commit }, user) {
            commit(UPDATING_USER)
            try {
                let response = await UserAPI.updateUser(user)
                commit(UPDATING_USER_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(UPDATING_USER_ERROR, error)
                return null
            }
        },
        async deleteUser({ commit }, user) {
            commit(DELETING_USER)
            try {
                let response = await UserAPI.deleteUser(user)
                commit(DELETING_USER_SUCCESS, response.status)
            } catch (error) {
                commit(DELETING_USER_ERROR, error)
                return null
            }
        }
    }
}
