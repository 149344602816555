import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Buefy from 'buefy'

import 'buefy/dist/buefy.css'
import './assets/css/app.scss'
import format from '@/_lib/format'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

window.__localeId__ = 'sv'

const i18n = new VueI18n({
    locale: window.__localeId__,
    messages: {
        sv: require('@/locales/sv.json')
    }
})

Vue.use(Buefy)

Vue.config.productionTip = false

Vue.prototype.$API_URL = process.env.API_URL

Vue.filter('formatDate', function(
    date,
    formatStr = 'yyyy-MM-dd',
    parseMethod,
    inputFormat
) {
    // eslint-disable-next-line prettier/prettier
    if (!date) return ''
    // return format(new Date(), 'yyyy')
    // eslint-disable-next-line prettier/prettier
    return format(date, formatStr, parseMethod, inputFormat)
})

/**
 * Vue filter to round the decimal to the given place.
 *
 * @param {String} value    The value string.
 * @param {Number} decimals The number of decimal places.
 */
Vue.filter('round', function(value, decimals) {
    if (!value) {
        value = 0
    }

    if (!decimals) {
        decimals = 0
    }

    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
    return value
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
