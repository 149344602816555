import CustomerAPI from '../api/customer'

const CREATING_CUSTOMER = 'CREATING_CUSTOMER'
const CREATING_CUSTOMER_SUCCESS = 'CREATING_CUSTOMER_SUCCESS'
const CREATING_CUSTOMER_ERROR = 'CREATING_CUSTOMER_ERROR'
const FETCHING_CUSTOMERS = 'FETCHING_CUSTOMER'
const FETCHING_CUSTOMERS_SUCCESS = 'FETCHING_CUSTOMER_SUCCESS'
const FETCHING_CUSTOMERS_ERROR = 'FETCHING_CUSTOMER_ERROR'
const UPDATING_CUSTOMER = 'UPDATING_CUSTOMER'
const UPDATING_CUSTOMER_SUCCESS = 'UPDATING_CUSTOMER_SUCCESS'
const UPDATING_CUSTOMER_ERROR = 'UPDATING_CUSTOMER_ERROR'
const DELETING_CUSTOMER = 'DELETING_CUSTOMER'
const DELETING_CUSTOMER_SUCCESS = 'DELETING_CUSTOMER_SUCCESS'
const DELETING_CUSTOMER_ERROR = 'DELETING_CUSTOMER_ERROR'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: null,
        customers: [],
        customer: [],
        status: null
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        hasError(state) {
            return state.error !== null
        },
        error(state) {
            return state.error
        },
        hasCustomers(state) {
            return state.customers.length > 0
        },
        customers(state) {
            return state.customers
        },
        customer(state) {
            return state.customer
        },
        status(state) {
            return state.status
        }
    },
    mutations: {
        [CREATING_CUSTOMER](state) {
            state.isLoading = true
            state.error = null
            state.status = null
        },
        [CREATING_CUSTOMER_SUCCESS](state, response) {
            state.isLoading = false
            state.error = null
            state.customers.unshift(response.data)
            state.status = response.status
        },
        [CREATING_CUSTOMER_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.customers = []
        },
        [FETCHING_CUSTOMERS](state) {
            state.isLoading = true
            state.error = null
            state.customers = []
        },
        [FETCHING_CUSTOMERS_SUCCESS](state, customers) {
            state.isLoading = false
            state.error = null
            state.customers = customers
        },
        [FETCHING_CUSTOMERS_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.customers = []
        },
        [UPDATING_CUSTOMER](state) {
            state.isLoading = true
            state.error = null
            state.customer = []
        },
        [UPDATING_CUSTOMER_SUCCESS](state, customer) {
            state.isLoading = false
            state.error = null
            state.customer = customer
        },
        [UPDATING_CUSTOMER_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.customer = []
        },
        [DELETING_CUSTOMER](state) {
            state.isLoading = true
            state.error = null
            state.customers = []
            state.status = null
        },
        [DELETING_CUSTOMER_SUCCESS](state, response) {
            state.isLoading = false
            state.error = null
            state.status = response.status
        },
        [DELETING_CUSTOMER_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.customers = []
        }
    },
    actions: {
        async create({ commit }, customerData) {
            commit(CREATING_CUSTOMER)
            try {
                let response = await CustomerAPI.create(customerData)
                commit(CREATING_CUSTOMER_SUCCESS, response)
                return response.data
            } catch (error) {
                commit(CREATING_CUSTOMER_ERROR, error)
                return null
            }
        },
        async update({ commit }, customer) {
            commit(UPDATING_CUSTOMER)
            try {
                let response = await CustomerAPI.update(customer)
                commit(UPDATING_CUSTOMER_SUCCESS, response)
                return response
            } catch (error) {
                commit(UPDATING_CUSTOMER_ERROR, error)
                return null
            }
        },
        async delete({ commit }, customer) {
            commit(DELETING_CUSTOMER)
            try {
                let response = await CustomerAPI.delete(customer)
                commit(DELETING_CUSTOMER_SUCCESS, response)
                return response.data
            } catch (error) {
                commit(DELETING_CUSTOMER_ERROR, error)
                return null
            }
        }
    }
}
