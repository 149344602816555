import HistoryAPI from '../api/history'

const FETCHING_YEARS = 'FETCHING_YEARS'
const FETCHING_YEARS_SUCCESS = 'FETCHING_YEARS_SUCCESS'
const FETCHING_YEARS_ERROR = 'FETCHING_YEARS_ERROR'
const FETCHING_HISTORY = 'FETCHING_HISTORY'
const FETCHING_HISTORY_SUCCESS = 'FETCHING_HISTORY_SUCCESS'
const FETCHING_HISTORY_ERROR = 'FETCHING_HISTORY_ERROR'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: null,
        history: [],
        years: []
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        hasError(state) {
            return state.error !== null
        },
        error(state) {
            return state.error
        },
        history(state) {
            return state.history
        },
        hasHistory(state) {
            return state.history !== null
        },
        years(state) {
            return state.years
        }
    },
    mutations: {
        [FETCHING_YEARS](state) {
            state.isLoading = true
            state.error = null
            state.history = []
        },
        [FETCHING_YEARS_SUCCESS](state, years) {
            state.isLoading = false
            state.error = null
            state.years = years
        },
        [FETCHING_YEARS_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.years = []
        },
        [FETCHING_HISTORY](state) {
            state.isLoading = true
            state.error = null
            state.history = []
        },
        [FETCHING_HISTORY_SUCCESS](state, history) {
            state.isLoading = false
            state.error = null
            state.history = history
        },
        [FETCHING_HISTORY_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.history = []
        }
    },
    actions: {
        async getYears({ commit }) {
            commit(FETCHING_YEARS)
            try {
                let response = await HistoryAPI.getYears()
                commit(FETCHING_YEARS_SUCCESS, response.data['hydra:member'])
                return response.data
            } catch (error) {
                commit(FETCHING_YEARS_ERROR, error)
                return null
            }
        },
        async getSpecificYear({ commit }, year) {
            commit(FETCHING_HISTORY)
            try {
                let response = await HistoryAPI.getSpecificYear(year)
                commit(FETCHING_HISTORY_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(FETCHING_HISTORY_ERROR, error)
                return null
            }
        }
    }
}
