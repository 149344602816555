import BookingAPI from '../api/booking'

const FETCHING_BOOKINGS = 'FETCHING_BOOKINGS'
const FETCHING_BOOKINGS_SUCCESS = 'FETCHING_BOOKINGS_SUCCESS'
const FETCHING_BOOKINGS_ERROR = 'FETCHING_BOOKINGS_ERROR'
const FETCHING_BOOKING = 'FETCHING_BOOKING'
const FETCHING_BOOKING_SUCCESS = 'FETCHING_BOOKING_SUCCESS'
const FETCHING_BOOKING_ERROR = 'FETCHING_BOOKING_ERROR'
const UPDATING_BOOKING = 'UPDATING_BOOKING'
const UPDATING_BOOKING_SUCCESS = 'UPDATING_BOOKING_SUCCESS'
const UPDATING_BOOKING_ERROR = 'UPDATING_BOOKING_ERROR'
const CREATING_BOOKING = 'CREATING_BOOKING'
const CREATING_BOOKING_SUCCESS = 'CREATING_BOOKING_SUCCESS'
const CREATING_BOOKING_ERROR = 'CREATING_BOOKING_ERROR'
const DELETE_BOOKING = 'DELETE_BOOKING'
const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS'
const DELETE_BOOKING_ERROR = 'DELETE_BOOKING_ERROR'
const DELETE_BOOKING_DENIED = 'DELETE_BOOKING_DENIED'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: null,
        bookings: [],
        booking: null
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        hasError(state) {
            return state.error !== null
        },
        error(state) {
            return state.error
        },
        hasBookings(state) {
            return state.bookings.length > 0
        },
        bookings(state) {
            return state.bookings
        },
        hasBooking(state) {
            return state.booking !== null
        },
        booking(state) {
            return state.booking
        }
    },
    mutations: {
        [FETCHING_BOOKINGS](state) {
            state.isLoading = true
            state.error = null
        },
        [FETCHING_BOOKINGS_SUCCESS](state, bookings) {
            state.isLoading = false
            state.error = null
            // state.bookings.unshift(booking)
            state.bookings = bookings
        },
        [FETCHING_BOOKINGS_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.bookings = []
        },
        [FETCHING_BOOKING](state) {
            state.isLoading = true
            state.error = null
        },
        [FETCHING_BOOKING_SUCCESS](state, booking) {
            state.isLoading = false
            state.error = null
            state.booking = booking
        },
        [FETCHING_BOOKING_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.booking = null
        },
        [CREATING_BOOKING](state) {
            state.isLoading = true
            state.error = null
        },
        [CREATING_BOOKING_SUCCESS](state, booking) {
            state.isLoading = false
            state.error = null
            state.bookings.unshift(booking)
        },
        [CREATING_BOOKING_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.bookings = []
        },
        [UPDATING_BOOKING](state) {
            state.isLoading = true
            state.error = null
        },
        [UPDATING_BOOKING_SUCCESS](state, booking) {
            state.isLoading = false
            state.error = null
            state.booking = booking
        },
        [UPDATING_BOOKING_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.booking = null
        },
        [DELETE_BOOKING](state) {
            state.isLoading = true
            state.error = null
        },
        [DELETE_BOOKING_SUCCESS](state, booking) {
            state.isLoading = false
            state.error = null
            state.bookings.splice(state.bookings.indexOf(booking), 1)
        },
        [DELETE_BOOKING_DENIED](state, error) {
            state.isLoading = false
            state.error = error
        },
        [DELETE_BOOKING_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.bookings = []
        }
    },
    actions: {
        async listBookings({ commit }) {
            commit(FETCHING_BOOKINGS)
            try {
                let response = await BookingAPI.listBookings()
                commit(FETCHING_BOOKINGS_SUCCESS, response.data['hydra:member'])
                return response.data['hydra:member']
            } catch (error) {
                commit(FETCHING_BOOKINGS_ERROR, error)
                return null
            }
        },
        async create({ commit }, bookingData) {
            commit(CREATING_BOOKING)
            try {
                let response = await BookingAPI.create(bookingData)
                commit(CREATING_BOOKING_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(CREATING_BOOKING_ERROR, error)
                return null
            }
        },
        async view({ commit }, booking) {
            commit(FETCHING_BOOKING)
            try {
                let response = await BookingAPI.view(booking)
                commit(FETCHING_BOOKING_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(FETCHING_BOOKING_ERROR, error)
                return null
            }
        },
        async update({ commit }, booking) {
            commit(UPDATING_BOOKING)
            try {
                let response = await BookingAPI.update(booking)
                commit(UPDATING_BOOKING_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(UPDATING_BOOKING_ERROR, error)
                return null
            }
        },
        async delete({ commit }, booking) {
            commit(DELETE_BOOKING)
            try {
                let response = await BookingAPI.delete(booking.uuid)
                commit(DELETE_BOOKING_SUCCESS, booking)
                return response.data
            } catch (error) {
                if (error.response.status === 403) {
                    commit(
                        DELETE_BOOKING_DENIED,
                        error.response.data['hydra:description']
                    )
                } else {
                    commit(CREATING_BOOKING_ERROR, error)
                }
                return null
            }
        }
    }
}
