import UsergroupAPI from '../api/usergroup'

const CREATING_USERGROUP = 'CREATING_USERGROUP'
const CREATING_USERGROUP_SUCCESS = 'CREATING_USERGROUP_SUCCESS'
const CREATING_USERGROUP_ERROR = 'CREATING_USERGROUP_ERROR'
const FETCHING_USERGROUP = 'FETCHING_USERGROUP'
const FETCHING_USERGROUP_SUCCESS = 'FETCHING_USERGROUP_SUCCESS'
const FETCHING_USERGROUP_ERROR = 'FETCHING_USERGROUP_ERROR'
const FETCHING_USERGROUPS = 'FETCHING_CUSTOMER'
const FETCHING_USERGROUPS_SUCCESS = 'FETCHING_CUSTOMER_SUCCESS'
const FETCHING_USERGROUPS_ERROR = 'FETCHING_CUSTOMER_ERROR'
const UPDATING_USERGROUP = 'UPDATING_USERGROUP'
const UPDATING_USERGROUP_SUCCESS = 'UPDATING_USERGROUP_SUCCESS'
const UPDATING_USERGROUP_ERROR = 'UPDATING_USERGROUP_ERROR'
const DELETING_USERGROUP = 'DELETING_USERGROUP'
const DELETING_USERGROUP_SUCCESS = 'DELETING_USERGROUP_SUCCESS'
const DELETING_USERGROUP_ERROR = 'DELETING_USERGROUP_ERROR'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: null,
        usergroups: [],
        usergroup: null,
        status: null
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        hasError(state) {
            return state.error !== null
        },
        error(state) {
            return state.error
        },
        hasUsergroups(state) {
            return state.usergroups.length > 0
        },
        usergroups(state) {
            return state.usergroups
        },
        usergroup(state) {
            return state.usergroup
        },
        status(state) {
            return state.status
        }
    },
    mutations: {
        [CREATING_USERGROUP](state) {
            state.isLoading = true
            state.error = null
        },
        [CREATING_USERGROUP_SUCCESS](state, usergroup) {
            state.isLoading = false
            state.error = null
            state.usergroups.unshift(usergroup)
        },
        [CREATING_USERGROUP_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.usergroups = []
        },
        [FETCHING_USERGROUP](state) {
            state.isLoading = true
            state.error = null
            state.usergroup = null
        },
        [FETCHING_USERGROUP_SUCCESS](state, usergroup) {
            state.isLoading = false
            state.error = null
            state.usergroup = usergroup
        },
        [FETCHING_USERGROUP_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.usergroup = null
        },
        [FETCHING_USERGROUPS](state) {
            state.isLoading = true
            state.error = null
            state.usergroups = []
        },
        [FETCHING_USERGROUPS_SUCCESS](state, usergroups) {
            state.isLoading = false
            state.error = null
            state.usergroups = usergroups
        },
        [FETCHING_USERGROUPS_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.usergroups = []
        },
        [UPDATING_USERGROUP](state) {
            state.isLoading = true
            state.error = null
        },
        [UPDATING_USERGROUP_SUCCESS](state, usergroup) {
            state.isLoading = false
            state.error = null
            state.usergroup = usergroup
        },
        [UPDATING_USERGROUP_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.usergroup = null
        },
        [DELETING_USERGROUP](state) {
            state.isLoading = true
            state.error = null
        },
        [DELETING_USERGROUP_SUCCESS](state, response) {
            state.isLoading = false
            state.error = null
            state.status = response.status
        },
        [DELETING_USERGROUP_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.usergroup = null
        }
    },
    actions: {
        async create({ commit }, usergroup) {
            commit(CREATING_USERGROUP)
            try {
                let response = await UsergroupAPI.create(usergroup)
                commit(CREATING_USERGROUP_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(CREATING_USERGROUP_ERROR, error)
                return null
            }
        },
        async view({ commit }, usergroup) {
            commit(FETCHING_USERGROUP)
            try {
                let response = await UsergroupAPI.view(usergroup)
                commit(FETCHING_USERGROUP_SUCCESS, response.data)
                return response
            } catch (error) {
                commit(FETCHING_USERGROUP_ERROR, error)
                return null
            }
        },
        async update({ commit }, usergroup) {
            commit(UPDATING_USERGROUP)
            try {
                let response = await UsergroupAPI.update(usergroup)
                commit(UPDATING_USERGROUP_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(UPDATING_USERGROUP_ERROR, error)
                return null
            }
        },
        async delete({ commit }, usergroup) {
            commit(DELETING_USERGROUP)
            try {
                let response = await UsergroupAPI.delete(usergroup)
                commit(DELETING_USERGROUP_SUCCESS, response)
            } catch (error) {
                commit(DELETING_USERGROUP_ERROR, error)
                return null
            }
        },
        async listAll({ commit }) {
            commit(FETCHING_USERGROUPS)
            try {
                let response = await UsergroupAPI.listAll()
                commit(
                    FETCHING_USERGROUPS_SUCCESS,
                    response.data['hydra:member']
                )
                return response.data['hydra:member']
            } catch (error) {
                commit(FETCHING_USERGROUPS_ERROR, error.message)
                return null
            }
        },
        async allCustomers({ commit }) {
            commit(FETCHING_USERGROUPS)
            try {
                let response = await UsergroupAPI.allCustomers()
                commit(
                    FETCHING_USERGROUPS_SUCCESS,
                    response.data['hydra:member']
                )
                return response.data['hydra:member']
            } catch (error) {
                commit(FETCHING_USERGROUPS_ERROR, error.message)
                return null
            }
        },
        async allSellers({ commit }) {
            commit(FETCHING_USERGROUPS)
            try {
                let response = await UsergroupAPI.allSellers()
                commit(
                    FETCHING_USERGROUPS_SUCCESS,
                    response.data['hydra:member']
                )
                return response.data['hydra:member']
            } catch (error) {
                commit(FETCHING_USERGROUPS_ERROR, error.message)
                return null
            }
        }
    }
}
