import EventAPI from '../api/event'

const CREATING_EVENT = 'CREATING_EVENT'
const CREATING_EVENT_SUCCESS = 'CREATING_EVENT_SUCCESS'
const CREATING_EVENT_ERROR = 'CREATING_EVENT_ERROR'
const FETCHING_EVENTS = 'FETCHING_EVENTS'
const FETCHING_EVENTS_SUCCESS = 'FETCHING_EVENTS_SUCCESS'
const FETCHING_EVENTS_ERROR = 'FETCHING_EVENTS_ERROR'
const FETCHING_BOOKINGS = 'FETCHING_BOOKINGS'
const FETCHING_BOOKINGS_SUCCESS = 'FETCHING_BOOKINGS_SUCCESS'
const FETCHING_BOOKINGS_ERROR = 'FETCHING_BOOKINGS_ERROR'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: null,
        events: [],
        bookings: []
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        hasError(state) {
            return state.error !== null
        },
        error(state) {
            return state.error
        },
        events(state) {
            return state.events
        },
        bookings(state) {
            return state.bookings
        }
    },
    mutations: {
        [CREATING_EVENT](state) {
            state.isLoading = true
            state.error = null
        },
        [CREATING_EVENT_SUCCESS](state, event) {
            state.isLoading = false
            state.error = null
            state.events.unshift(event)
        },
        [CREATING_EVENT_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.events = []
        },
        [FETCHING_EVENTS](state) {
            state.isLoading = true
            state.error = null
            state.events = []
        },
        [FETCHING_EVENTS_SUCCESS](state, events) {
            state.isLoading = false
            state.error = null
            state.events = events
        },
        [FETCHING_EVENTS_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.events = []
        },
        [FETCHING_BOOKINGS](state) {
            state.isLoading = true
            state.error = null
            state.bookings = []
        },
        [FETCHING_BOOKINGS_SUCCESS](state, bookings) {
            state.isLoading = false
            state.error = null
            state.bookings = bookings
        },
        [FETCHING_BOOKINGS_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.bookings = []
        }
    },
    actions: {
        async create({ commit }, event) {
            commit(CREATING_EVENT)
            try {
                let response = await EventAPI.create(event)
                commit(CREATING_EVENT_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(CREATING_EVENT_ERROR, error)
                return null
            }
        },
        async list({ commit }, period) {
            commit(FETCHING_EVENTS)
            try {
                let response = await EventAPI.list(period)
                commit(FETCHING_EVENTS_SUCCESS, response.data['hydra:member'])
                return response.data
            } catch (error) {
                commit(FETCHING_EVENTS_ERROR, error)
                return null
            }
        },
        async bookings({ commit }, period) {
            commit(FETCHING_BOOKINGS)
            try {
                let response = await EventAPI.bookings(period)
                commit(FETCHING_BOOKINGS_SUCCESS, response.data['hydra:member'])
                return response.data
            } catch (error) {
                commit(FETCHING_BOOKINGS_ERROR, error)
                return null
            }
        }
    }
}
