import Vue from 'vue'
import Vuex from 'vuex'
import SecurityModule from './security'
import BookingModule from './booking'
import UsergroupModule from './usergroup'
import CustomerModule from './customer'
import ProductModule from './product'
import UserModule from './user'
import HistoryModule from './history'
import EventModule from './event'
import CalendarModule from './calendar'
import MediaSetModule from './mediaset'
import usergroupAccessModule from './usergroupAccess'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        security: SecurityModule,
        booking: BookingModule,
        usergroup: UsergroupModule,
        customer: CustomerModule,
        product: ProductModule,
        user: UserModule,
        history: HistoryModule,
        event: EventModule,
        calendar: CalendarModule,
        mediaset: MediaSetModule,
        usergroupAccess: usergroupAccessModule
    }
})
