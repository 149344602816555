<template>
    <aside
        id="menu"
        class="column
        has-background-info
        is-narrow-mobile
        is-fullheight
        section
        is-hidden-mobile
        is-narrow"
    >
        <nav
            class="navbar is-fixed-top has-background-info has-text-centered"
            style="width: 100px;"
        >
            <ul class="menu-list">
                <li class="menu-label section has-text-white">
                    {{ $t('sideMenu.companyName') }}
                </li>

                <li class="content">
                    <router-link
                        :to="{ name: 'Overview' }"
                        class="has-text-white"
                    >
                        <span class="icon">
                            <b-icon
                                icon="note-multiple-outline"
                                type="is-white"
                            />
                        </span>
                        <br /><small
                            class="is-uppercase is-size-7 has-text-weight-medium"
                        >
                            {{ $t('sideMenu.overview') }}
                        </small>
                    </router-link>
                </li>

                <li class="content">
                    <router-link to="/calendar" class="has-text-white">
                        <span class="icon">
                            <b-icon icon="calendar" type="is-white" />
                        </span>
                        <br />
                        <small
                            class="is-uppercase is-size-7 has-text-weight-medium"
                        >
                            {{ $t('sideMenu.calendar') }}
                        </small>
                    </router-link>
                </li>

                <li class="content">
                    <router-link to="/history" class="has-text-white">
                        <span class="icon">
                            <b-icon icon="history" type="is-white" />
                        </span>
                        <br /><small
                            class="is-uppercase is-size-7 has-text-weight-medium"
                        >
                            {{ $t('sideMenu.history') }}
                        </small>
                    </router-link>
                </li>

                <li class="content is-hidden">
                    <router-link to="/gallery" class="has-text-white">
                        <span class="icon">
                            <b-icon icon="image-filter" type="is-white" />
                        </span>
                        <br /><small
                            class="is-uppercase is-size-7 has-text-weight-medium"
                        >
                            {{ $t('sideMenu.gallery') }}
                        </small>
                    </router-link>
                </li>

                <li v-if="!isCustomer" class="content">
                    <router-link
                        :to="{ name: 'ListCustomer' }"
                        class="has-text-white"
                    >
                        <span class="icon">
                            <b-icon icon="account-group" type="is-white" />
                        </span>
                        <br />
                        <small
                            class="is-uppercase is-size-7 has-text-weight-medium"
                        >
                            {{ $t('sideMenu.users') }}
                        </small>
                    </router-link>
                </li>
                <li v-if="!isCustomer" class="content">
                    <a href="#" class="has-text-white">
                        <span class="icon">
                            <b-icon icon="cash-multiple" type="is-white" />
                        </span>
                        <br />
                        <small
                            class="is-uppercase is-size-7 has-text-weight-medium"
                        >
                            {{ $t('sideMenu.economy') }}
                        </small>
                    </a>
                </li>
            </ul>
        </nav>
        <nav
            class="navbar is-fixed-bottom has-background-info"
            style="width: 100px;"
        >
            <div class="section">
                <ul class="menu-list">
                    <li
                        class="footer-li"
                        role="button"
                        style="padding-bottom: 20px;"
                    >
                        <router-link
                            exact-active-class=""
                            active-class=""
                            :to="{
                                name: 'NewBooking',
                                params: { mobile: true }
                            }"
                        >
                            <b-icon icon="plus" type="is-white" />
                        </router-link>
                        <router-link
                            class="is-hidden"
                            :to="{
                                name: 'NewBookingOld',
                                params: { mobile: false }
                            }"
                        >
                            <b-icon icon="plus" type="is-white" />
                        </router-link>
                    </li>
                    <li
                        class="footer-li"
                        role="button"
                        style="padding-left: 12px;"
                        @click="makeReservation = !makeReservation"
                    >
                        <b-icon icon="bell-outline" type="is-white" />
                    </li>

                    <b-dropdown
                        hoverable
                        aria-role="list"
                        position="is-top-right"
                        class="is-centered"
                    >
                        <li
                            slot="trigger"
                            class="content footer-li"
                            role="button"
                            style="padding-left: 12px;"
                        >
                            <span class="icon">
                                <b-icon
                                    icon="dots-horizontal"
                                    type="is-white"
                                />
                            </span>
                        </li>

                        <b-dropdown-item has-link aria-role="listitem">
                            <router-link
                                :to="{ name: 'Profile' }"
                                exact-active-class="has-background-light"
                                active-class="has-background-light"
                            >
                                <p class="is-uppercase has-text-weight-medium">
                                    <span class="icon">
                                        <b-icon
                                            icon="account-circle-outline"
                                            size="is-small"
                                        />
                                    </span>
                                    {{ $t('sideMenu.profile') }}
                                </p>
                            </router-link>
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="isSeller"
                            has-link
                            aria-role="listitem"
                        >
                            <router-link
                                :to="{ name: 'ListProduct' }"
                                exact-active-class="has-background-light"
                                active-class="has-background-light"
                            >
                                <p class="is-uppercase has-text-weight-medium">
                                    <span class="icon">
                                        <b-icon
                                            icon="briefcase-plus"
                                            size="is-small"
                                        />
                                    </span>
                                    {{ $t('sideMenu.products') }}
                                </p>
                            </router-link>
                        </b-dropdown-item>
                        <b-dropdown-item has-link aria-role="listitem">
                            <router-link
                                :to="{ name: 'Settings' }"
                                exact-active-class="has-background-light"
                                active-class="has-background-light"
                            >
                                <p class="is-uppercase has-text-weight-medium">
                                    <span class="icon">
                                        <b-icon
                                            icon="settings-outline"
                                            size="is-small"
                                        />
                                    </span>
                                    {{ $t('sideMenu.settings') }}
                                </p>
                            </router-link>
                        </b-dropdown-item>
                        <b-dropdown-item has-link aria-role="logout">
                            <a @click="confirmLogout">
                                <p class="is-uppercase has-text-weight-medium">
                                    <span class="icon">
                                        <b-icon
                                            icon="exit-to-app"
                                            size="is-small"
                                        />
                                    </span>
                                    {{ $t('sideMenu.logout') }}
                                </p>
                            </a>
                        </b-dropdown-item>
                    </b-dropdown>
                </ul>
            </div>
        </nav>
        <b-modal
            :active.sync="makeReservation"
            has-modal-card
            trap-focus
            aria-role="dialog"
            aria-modal
        >
            <ReservationModal v-bind="formProps" />
        </b-modal>
    </aside>
</template>

<script>
import ReservationModal from './ReservationModal'
export default {
    name: 'SideMenu',
    components: { ReservationModal },
    data() {
        return {
            makeReservation: false,
            formProps: {
                email: '',
                password: '',
                type: null,
                block: null
            }
        }
    },
    computed: {
        typeBlock() {
            return (
                (this.type === 'reservation') | (this.type === 'block') || false
            )
        },
        isSeller() {
            return this.$store.getters['security/hasRole']('ROLE_SELLER')
        },
        isCustomer() {
            return this.$store.getters['security/hasRole']('ROLE_CUSTOMER')
        }
    },
    methods: {
        confirmLogout() {
            this.$buefy.dialog.confirm({
                message: this.$t('sideMenu.confirmLogout'),
                cancelText: this.$t('sideMenu.cancelButton'),
                confirmText: this.$t('sideMenu.confirmButton'),
                onConfirm: async () => {
                    // this.$router.push('logout')
                    await this.$store.dispatch('security/logout')
                    if (!this.$store.getters['security/hasError']) {
                        this.$router.push({ path: '/login' }).catch(() => {})
                    }
                }
            })
        }
    }
}
</script>
