import axios from './axios'

export default {
    create(access) {
        return axios.post('/usergroup_accesses', {
            userAccount: access.user,
            usergroup: access.usergroup
        })
    },
    delete(access) {
        return axios.delete(access)
    }
}
