import ProductAPI from '../api/product'

const CREATING_PRODUCT = 'CREATING_PRODUCT'
const CREATING_PRODUCT_SUCCESS = 'CREATING_PRODUCT_SUCCESS'
const CREATING_PRODUCT_ERROR = 'CREATING_PRODUCT_ERROR'
const FETCHING_PRODUCTS = 'FETCHING_PRODUCTS'
const FETCHING_PRODUCTS_SUCCESS = 'FETCHING_PRODUCTS_SUCCESS'
const FETCHING_PRODUCTS_ERROR = 'FETCHING_PRODUCTS_ERROR'
const DELETING_PRODUCT = 'DELETING_PRODUCT'
const DELETING_PRODUCT_SUCCESS = 'DELETING_PRODUCT_SUCCESS'
const DELETING_PRODUCT_ERROR = 'DELETING_PRODUCT_ERROR'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: null,
        products: []
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        hasError(state) {
            return state.error !== null
        },
        error(state) {
            return state.error
        },
        hasProducts(state) {
            return state.products.length > 0
        },
        products(state) {
            return state.products
        }
    },
    mutations: {
        [CREATING_PRODUCT](state) {
            state.isLoading = true
            state.error = null
        },
        [CREATING_PRODUCT_SUCCESS](state, product) {
            state.isLoading = false
            state.error = null
            state.products.unshift(product)
        },
        [CREATING_PRODUCT_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.products = []
        },
        [FETCHING_PRODUCTS](state) {
            state.isLoading = true
            state.error = null
            state.products = []
        },
        [FETCHING_PRODUCTS_SUCCESS](state, products) {
            state.isLoading = false
            state.error = null
            state.products = products
        },
        [FETCHING_PRODUCTS_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.products = []
        },
        [DELETING_PRODUCT](state) {
            state.isLoading = true
            state.error = null
        },
        [DELETING_PRODUCT_SUCCESS](state, product) {
            state.isLoading = false
            state.error = null
            state.products.splice(state.products.indexOf(product), 1)
        },
        [DELETING_PRODUCT_ERROR](state, error) {
            state.isLoading = false
            state.error = error
        }
    },
    actions: {
        async create({ commit }, product) {
            commit(CREATING_PRODUCT)
            try {
                let response = await ProductAPI.create(product)
                commit(CREATING_PRODUCT_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(CREATING_PRODUCT_ERROR, error)
                return null
            }
        },
        async listAll({ commit }, user) {
            commit(FETCHING_PRODUCTS)
            try {
                let response = await ProductAPI.listAll(user)
                commit(FETCHING_PRODUCTS_SUCCESS, response.data['hydra:member'])
                return response.data['hydra:member']
            } catch (error) {
                commit(FETCHING_PRODUCTS_ERROR, error.message)
                return null
            }
        },
        async delete({ commit }, product) {
            commit(DELETING_PRODUCT)
            try {
                let response = await ProductAPI.delete(product)
                commit(DELETING_PRODUCT_SUCCESS, product)
                return response.data
            } catch (error) {
                commit(DELETING_PRODUCT_ERROR, error.message)
                return null
            }
        }
    }
}
