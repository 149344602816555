var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"modal-card is-relative",attrs:{"width":740}},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading,"can-cancel":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('header',{staticClass:"modal-card-head is-info"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.$t('reservationModal.newEvent'))+" ")])]),_c('section',{staticClass:"modal-card-body"},[_c('b-field',{attrs:{"grouped":""}},[_c('b-field',{attrs:{"expanded":""}},[_c('b-field',[_c('b-select',{attrs:{"placeholder":_vm.$t('reservationModal.type'),"required":"","icon":"pin"},model:{value:(_vm.event.type),callback:function ($$v) {_vm.$set(_vm.event, "type", $$v)},expression:"event.type"}},[_c('option',{attrs:{"value":"reservation"}},[_vm._v(" "+_vm._s(_vm.$t('reservationModal.reservation'))+" ")]),_c('option',{attrs:{"value":"block"}},[_vm._v(" "+_vm._s(_vm.$t('reservationModal.block'))+" ")]),_c('option',{attrs:{"value":"note"}},[_vm._v(" "+_vm._s(_vm.$t('reservationModal.note'))+" ")]),_c('option',{attrs:{"value":"private"}},[_vm._v(" "+_vm._s(_vm.$t('reservationModal.private'))+" ")])])],1),_c('b-field',{attrs:{"expanded":""}},[_c('b-input',{attrs:{"type":"text","placeholder":_vm.$t('reservationModal.subject'),"required":"","icon":"format-quote-close"},model:{value:(_vm.event.title),callback:function ($$v) {_vm.$set(_vm.event, "title", $$v)},expression:"event.title"}})],1)],1)],1),_c('b-field',{attrs:{"grouped":""}},[_c('b-field',[_c('b-datetimepicker',{attrs:{"value":_vm.now,"rounded":"","placeholder":_vm.$t('reservationModal.beginsAt.placeholder'),"icon":"calendar-today","required":"","min-datetime":_vm.now,"editable":"","append-to-body":true,"first-day-of-week":"1","datepicker":{
                            showWeekNumber: true,
                            firstDayOfWeek: 1
                        },"timepicker":{
                            enableSeconds: false,
                            hourFormat: '24',
                            incrementMinutes: 5
                        }},model:{value:(_vm.event.beginAt),callback:function ($$v) {_vm.$set(_vm.event, "beginAt", $$v)},expression:"event.beginAt"}})],1),_c('b-field',{attrs:{"expanded":""}},[_c('b-datetimepicker',{attrs:{"value":_vm.now,"rounded":"","append-to-body":true,"placeholder":_vm.$t('reservationModal.endsAt.placeholder'),"icon":"calendar-today","required":"","min-datetime":_vm.now,"first-day-of-week":"1","editable":"","datepicker":{
                            showWeekNumber: true,
                            firstDayOfWeek: 1
                        },"timepicker":{
                            enableSeconds: false,
                            hourFormat: '24',
                            incrementMinutes: 5
                        }},model:{value:(_vm.event.endAt),callback:function ($$v) {_vm.$set(_vm.event, "endAt", $$v)},expression:"event.endAt"}})],1)],1),_c('b-field',{attrs:{"label":_vm.$t('reservationModal.note')}},[_c('b-input',{attrs:{"maxlength":"200","type":"textarea","has-counter":true},model:{value:(_vm.event.note),callback:function ($$v) {_vm.$set(_vm.event, "note", $$v)},expression:"event.note"}})],1),_c('div',{staticClass:"field"},[_c('b-checkbox',{attrs:{"date":"block","checked":_vm.typeBlock},model:{value:(_vm.event.blockCalendar),callback:function ($$v) {_vm.$set(_vm.event, "blockCalendar", $$v)},expression:"event.blockCalendar"}},[_vm._v(" "+_vm._s(_vm.$t('reservationModal.blockInCalendar'))),_c('br'),_c('span',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(_vm.$t('reservationModal.blockInfo'))+" ")])])],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{staticClass:"is-primary",attrs:{"icon-left":"bell-check-outline"},on:{"click":_vm.saveEvent}},[_vm._v(" Save ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }