<template>
    <span v-if="!isAuthenticated">
        <router-view />
    </span>
    <div v-else class="sidebar-page">
        <section class="sidebar-layout">
            <b-sidebar
                position="static"
                :fullheight="true"
                mobile="hide"
                :reduce="true"
                type="has-background-info"
                open
            >
                <div class="p-1">
                    <SideMenu />
                </div>
            </b-sidebar>
            <div class="p-1 container is-fluid">
                <section id="main" class="main-content">
                    <MobileNavbar />

                    <div class="is-centered">
                        <router-view />
                    </div>
                </section>
            </div>
        </section>
    </div>
</template>

<script>
import axios from './api/axios'
import update from './mixins/update'
import MobileNavbar from './components/MobileNavbar'
import SideMenu from './components/SideMenu'

export default {
    name: 'App',
    components: { MobileNavbar, SideMenu },
    mixins: [update],
    data() {
        return {
            wait: true
        }
    },
    computed: {
        userDetails() {
            return this.$store.getters['security/user']
        },
        isLoading() {
            return this.$store.getters['security/isLoading']
        },
        isAuthed() {
            return this.$store.getters['security/isAuthenticated']
        },
        isAuthenticated() {
            return this.$store.getters['security/isAuthenticated']
        }
    },
    created() {
        this.$store.dispatch('security/checkAuth')
        // this.$store.dispatch('security/onRefresh', payload)

        axios.interceptors.response.use(undefined, err => {
            return new Promise(() => {
                if (err.response.status === 401) {
                    this.$store.dispatch('security/onRefresh', {
                        isAuthenticated: false,
                        user: null
                    })
                    this.$router.push({ path: '/login' }).catch(() => {})
                } else if (err.response.status === 500) {
                    if (
                        err.response.headers['content-type'] ===
                        'application/ld+json; charset=utf-8'
                    ) {
                        // console.log(err.response.data)
                        this.$buefy.snackbar.open({
                            indefinite: true,
                            message: err.response.data['hydra:description'],
                            type: 'is-danger',
                            position: 'is-bottom-left',
                            actionText: 'OK',
                            queue: false,
                            onAction: () => {}
                        })
                    } else {
                        // console.log(err)
                        console.log(err.response.data)
                        document.open()
                        document.write(err.response.data)
                        document.close()
                    }
                    //  document.open()
                    // document.write(err)
                    //  document.write(err.response.data)
                    //  document.close()
                } else {
                    if (
                        this.isAuthenticated &&
                        err.response.headers['content-type'] ===
                            'application/ld+json; charset=utf-8'
                    ) {
                        //  console.log(err.response.data)
                        this.$buefy.snackbar.open({
                            indefinite: true,
                            message: err.response.data['hydra:description'],
                            type: 'is-danger',
                            position: 'is-bottom-left',
                            actionText: 'OK',
                            queue: false,
                            onAction: () => {
                                // something
                            }
                        })
                    }
                }
                throw err
            })
        })
    }
}
</script>

<style lang="scss">
.p-1 {
    padding: 1em;
}
.sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    min-height: 100vh;
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        min-height: 100vh;
    }
}
@media screen and (max-width: 1023px) {
    .b-sidebar {
        .sidebar-content {
            &.is-mini-mobile {
                &:not(.is-mini-expand),
                &.is-mini-expand:not(:hover) {
                    .menu-list {
                        li {
                            a {
                                span:nth-child(2) {
                                    display: none;
                                }
                            }
                            ul {
                                padding-left: 0;
                                li {
                                    a {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                    .menu-label:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .b-sidebar {
        .sidebar-content {
            &.is-mini {
                width: 100px !important;
                &:not(.is-mini-expand),
                &.is-mini-expand:not(:hover) {
                    .menu-list {
                        li {
                            a {
                                span:nth-child(2) {
                                    display: none;
                                }
                            }
                            ul {
                                padding-left: 0;
                                li {
                                    a {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                    .menu-label:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
</style>
