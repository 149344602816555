import axios from './axios'

export default {
    login(login, password) {
        // return axios.post('/auth', {
        return axios.post(
            '/login',
            {
                email: login,
                password: password
            },
            {
                // baseURL: '/',
                withCredentials: true
            }
        )
    },
    checkAuth() {
        return axios.get('/login')
    },
    requestReset(email) {
        return axios.post('/reset_password', { email: email })
    },
    changePassword(payload) {
        return axios.put('/reset_password/reset/' + payload.token, {
            password: payload.password
        })
    },
    logout() {
        return axios.get('/logout')
    }
}
