import axios from './axios'

export default {
    listBookings() {
        return axios.get('/bookings')
    },
    listConfirmed() {
        return axios.get(
            '/bookings?status.event=confirmed&status.category=booking&pagination=false'
        )
    },
    view(booking) {
        return axios.get('/bookings/' + booking)
    },
    update(booking) {
        return axios.patch(booking['@id'], booking, {
            headers: {
                'Content-Type': 'application/merge-patch+json'
            }
        })
    },
    create(data) {
        return axios.post('/bookings', data)
    },
    delete(booking) {
        return axios.delete('/bookings/' + booking)
    }
}
