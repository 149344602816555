import axios from './axios'

export default {
    create(product) {
        return axios.post('/products', {
            name: product.name,
            length: product.length,
            description: product.description,
            vat: product.vat,
            defaultPrice: product.price,
            rgbColor: product.color,
            specificUsergroup: product.specificUsergroup
        })
    },
    listAll(user) {
        // let parsedUser = user.replace('/users/?pagination=false', '')
        return axios.get('/products?specificUsergroup=' + user)
    },
    delete(product) {
        return axios.delete(product['@id'])
    }
}
