import axios from './axios'

export default {
    create(booking) {
        return axios.post('/media_sets', {
            booking: booking['@id']
        })
    },
    view(booking) {
        return axios.get('/media_sets?booking.uuid=' + booking)
    }
}
