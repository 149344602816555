import axios from './axios'

export default {
    getYears() {
        return axios.get('/histories/?pagination=false')
    },
    getSpecificYear(year) {
        return axios.get('/histories/' + year + '?pagination=false')
    }
}
