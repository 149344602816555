<template>
    <div>
        <div class="modal-card is-relative" :width="740">
            <b-loading
                :is-full-page="false"
                :active.sync="isLoading"
                :can-cancel="false"
            />
            <header class="modal-card-head is-info">
                <p class="modal-card-title">
                    {{ $t('reservationModal.newEvent') }}
                </p>
            </header>
            <section class="modal-card-body">
                <b-field grouped>
                    <b-field expanded>
                        <b-field>
                            <b-select
                                v-model="event.type"
                                :placeholder="$t('reservationModal.type')"
                                required
                                icon="pin"
                            >
                                <option value="reservation">
                                    {{ $t('reservationModal.reservation') }}
                                </option>
                                <option value="block">
                                    {{ $t('reservationModal.block') }}
                                </option>
                                <option value="note">
                                    {{ $t('reservationModal.note') }}
                                </option>
                                <option value="private">
                                    {{ $t('reservationModal.private') }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field expanded>
                            <b-input
                                v-model="event.title"
                                type="text"
                                :placeholder="$t('reservationModal.subject')"
                                required
                                icon="format-quote-close"
                            />
                        </b-field>
                    </b-field>
                </b-field>

                <b-field grouped>
                    <b-field>
                        <b-datetimepicker
                            v-model="event.beginAt"
                            :value="now"
                            rounded
                            :placeholder="
                                $t('reservationModal.beginsAt.placeholder')
                            "
                            icon="calendar-today"
                            required
                            :min-datetime="now"
                            editable
                            :append-to-body="true"
                            first-day-of-week="1"
                            :datepicker="{
                                showWeekNumber: true,
                                firstDayOfWeek: 1
                            }"
                            :timepicker="{
                                enableSeconds: false,
                                hourFormat: '24',
                                incrementMinutes: 5
                            }"
                        />
                    </b-field>
                    <b-field expanded>
                        <b-datetimepicker
                            v-model="event.endAt"
                            :value="now"
                            rounded
                            :append-to-body="true"
                            :placeholder="
                                $t('reservationModal.endsAt.placeholder')
                            "
                            icon="calendar-today"
                            required
                            :min-datetime="now"
                            first-day-of-week="1"
                            editable
                            :datepicker="{
                                showWeekNumber: true,
                                firstDayOfWeek: 1
                            }"
                            :timepicker="{
                                enableSeconds: false,
                                hourFormat: '24',
                                incrementMinutes: 5
                            }"
                        />
                    </b-field>
                </b-field>

                <b-field :label="$t('reservationModal.note')">
                    <b-input
                        v-model="event.note"
                        maxlength="200"
                        type="textarea"
                        :has-counter="true"
                    />
                </b-field>

                <div class="field">
                    <b-checkbox
                        v-model="event.blockCalendar"
                        date="block"
                        :checked="typeBlock"
                    >
                        {{ $t('reservationModal.blockInCalendar') }}<br />
                        <span class="is-size-7">
                            {{ $t('reservationModal.blockInfo') }}
                        </span>
                    </b-checkbox>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button
                    class="is-primary"
                    icon-left="bell-check-outline"
                    @click="saveEvent"
                >
                    Save
                </b-button>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReservationModal',
    props: {
        date: Date,
        allDay: Boolean,
        start: Date,
        end: Date
    },
    data() {
        return {
            event: {
                type: null,
                title: '',
                beginAt: null,
                endAt: null,
                note: null,
                blockCalendar: false
            },
            eventFormReset: null
        }
    },
    computed: {
        typeBlock() {
            return (
                (this.type === 'reservation') | (this.type === 'block') || false
            )
        },
        now() {
            return new Date()
        },
        isLoading() {
            return this.$store.getters['event/isLoading']
        },
        hasError() {
            return this.$store.getters['event/hasError']
        }
    },
    methods: {
        async saveEvent() {
            this.event.beginAt = this.event.beginAt.toUTCString()
            this.event.endAt = this.event.endAt.toUTCString()
            await this.$store.dispatch('event/create', this.event)
            if (!this.hasError) {
                this.$parent.close()

                let message = ''

                if (this.event.blockCalendar) {
                    message = this.$t('reservationModal.saved.blockInCalendar')
                } else {
                    message = this.$t('reservationModal.saved.noBlock')
                }

                this.$buefy.snackbar.open({
                    indefinite: false,
                    message: message,
                    type: 'is-success',
                    position: 'is-bottom-left',
                    actionText: 'OK',
                    queue: true
                })

                this.event = { ...this.eventFormReset }
            }
        }
    },
    created() {
        // console.log(this.start)
        // let beginAt = !this.allDay ? this.start : this.date
        let beginAt = this.start
        let endAt = !this.allDay ? this.end : this.end
        // .setHours(23).setMinutes(59)
        this.event = {
            title: this.title,
            beginAt: beginAt,
            endAt: endAt,
            blockCalendar: false
        }
        this.eventFormReset = { ...this.event }
    }
}
</script>
