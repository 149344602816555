<template>
    <b-navbar class="has-shadow is-hidden-desktop is-hidden-tablet">
        <template slot="brand">
            <b-navbar-item>
                <img
                    src="@/assets/img/greylight-sm-logo.png"
                    :alt="$t('navbar.mobile.logoText')"
                />
            </b-navbar-item>
        </template>

        <template slot="start">
            <b-navbar-item tag="router-link" :to="{ name: 'Overview' }">
                {{ $t('navbar.mobile.overview') }}
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ name: 'Calendar' }">
                {{ $t('navbar.mobile.calendar') }}
            </b-navbar-item>
        </template>

        <template slot="end">
            <b-navbar-item tag="div">
                <div class="buttons">
                    <b-button
                        tag="router-link"
                        :to="{ name: 'NewBooking' }"
                        class="button is-success is-pulled-right is-rounded is-uppercase"
                        icon-left="plus-circle-outline"
                    >
                        {{ $t('navbar.mobile.newOrder') }}
                    </b-button>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
export default {
    name: 'MobileNavbar',
    data() {
        return {}
    }
}
</script>
