import axios from './axios'

export default {
    create(customer) {
        let role =
            customer.type === 'customerAdmin'
                ? 'ROLE_GROUP_ADMIN'
                : 'ROLE_CUSTOMER'
        return axios.post('/users', {
            email: customer.email,
            username: customer.username,
            firstName: customer.firstName,
            lastName: customer.lastName,
            phone: customer.phone,
            roles: role,
            usergroup: customer.officeIri
        })
    },
    update(customer) {
        customer.role =
            customer.type === 'customerAdmin'
                ? 'ROLE_GROUP_ADMIN'
                : 'ROLE_CUSTOMER'
        return axios.patch(customer.iri, customer, {
            headers: {
                'Content-Type': 'application/merge-patch+json'
            }
        })
    },
    delete(customer) {
        return axios.delete(customer)
    }
}
