import axios from './axios'
import qs from 'qs'

export default {
    create(timeslot) {
        return axios.post('/calendar_timeslots?pagination=false', timeslot)
    },
    list(period) {
        return axios.get('/calendar_timeslots?pagination=false', {
            params: {
                startAt: {
                    after: period.start,
                    before: period.end
                }
            },
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            }
        })
    }
}
