import MediaSetAPI from '../api/mediaset'

const CREATING_MEDIA_SET = 'CREATING_MEDIA_SET'
const CREATING_MEDIA_SET_SUCCESS = 'CREATING_MEDIA_SET_SUCCESS'
const CREATING_MEDIA_SET_ERROR = 'CREATING_MEDIA_SET_ERROR'
const FETCHING_MEDIA_SET = 'FETCHING_MEDIA_SET'
const FETCHING_MEDIA_SET_SUCCESS = 'FETCHING_MEDIA_SET_SUCCESS'
const FETCHING_MEDIA_SET_ERROR = 'FETCHING_MEDIA_SET_ERROR'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        error: null,
        mediaset: null
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        hasError(state) {
            return state.error !== null
        },
        error(state) {
            return state.error
        },
        mediaset(state) {
            return state.mediaset
        }
    },
    mutations: {
        [CREATING_MEDIA_SET](state) {
            state.isLoading = true
            state.error = null
        },
        [CREATING_MEDIA_SET_SUCCESS](state, mediaset) {
            state.isLoading = false
            state.error = null
            state.mediaset = mediaset
        },
        [CREATING_MEDIA_SET_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.mediaset = null
        },
        [FETCHING_MEDIA_SET](state) {
            state.isLoading = true
            state.error = null
        },
        [FETCHING_MEDIA_SET_SUCCESS](state, mediaset) {
            state.isLoading = false
            state.error = null
            state.mediaset = mediaset
        },
        [FETCHING_MEDIA_SET_ERROR](state, error) {
            state.isLoading = false
            state.error = error
            state.mediaset = null
        }
    },
    actions: {
        async create({ commit }, booking) {
            commit(CREATING_MEDIA_SET)
            try {
                let response = await MediaSetAPI.create(booking)
                commit(CREATING_MEDIA_SET_SUCCESS, response.data)
                return response.data
            } catch (error) {
                commit(CREATING_MEDIA_SET_ERROR, error)
                return null
            }
        },
        async view({ commit }, mediaset) {
            commit(FETCHING_MEDIA_SET)
            try {
                let response = await MediaSetAPI.view(mediaset)
                commit(
                    FETCHING_MEDIA_SET_SUCCESS,
                    response.data['hydra:member']
                )
                return response.data
            } catch (error) {
                commit(FETCHING_MEDIA_SET_ERROR, error)
                return null
            }
        }
    }
}
